import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { readLoginError } from '@/store/main/getters';
import { mainStore } from '@/store';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.login = '';
        this.password = '';
        this.valid = true;
        this.showPassword = false;
        this.isLoading = false;
        this.admin = false;
        this.otp = '';
        this.loading2FA = false;
        this.companySelected = null;
    }
    get companiesToLogin() {
        return mainStore.companiesToLogin;
    }
    get francisingName() {
        if (this.isKnnUrl) {
            return 'Phenom';
        }
        return 'KNN';
    }
    get isKnnUrl() {
        return window.location.hostname.includes('knn');
    }
    get isPhenomUrl() {
        return window.location.hostname.includes('phenom');
    }
    get isLogin() {
        return this.$route.path.includes('login');
    }
    get isPortal() {
        return this.$route.path.includes('portal');
    }
    get loginError() {
        return readLoginError(this.$store);
    }
    get habilitaLogin2FA() {
        return mainStore.habilitadoLogin2FA;
    }
    async submit() {
        if (this.companiesToLogin.length > 1 && !this.companySelected) {
            return;
        }
        const companyId = this.companiesToLogin.length > 1 ? this.companySelected.company_id : 0;
        this.isLoading = true;
        // @ts-ignore
        grecaptcha.ready(async () => {
            // @ts-ignore
            grecaptcha
                .execute('6LfBEfIiAAAAABz3DtRSX1mM8yGSI_3iniAYVrQm', {
                action: 'submit',
            })
                .then(async (token) => {
                await mainStore.logIn({
                    company_id: companyId,
                    username: this.login,
                    password: this.password,
                    admin: this.admin,
                    gToken: token,
                    codeTwoFA: this.otp || '',
                });
                this.isLoading = false;
            });
        });
    }
    async mounted() {
        this.admin = this.$router.currentRoute.query.admin === 'true';
    }
};
Login = __decorate([
    Component
], Login);
export default Login;
